<template>
    <div class="page">
        <div class="my-top">
            <div class="info-box" style="">
                <div class="head-portrait">
                    <div class="box">
                        <div class="treasure-font">
                            -积分-
                        </div>
                        <div class="treasure-num">{{ userInfo.integralNum }}</div>
                      <div class="treasure-font">
                        <van-button plain hairline round type="info" size="small" class="treasure-btn-sign" @click="signIntegral">-{{ isSign?'已签到':'签到' }}-</van-button>
                      </div>
                    </div>
<!--                    <van-button plain hairline round type="info" size="small" class="treasure-btn" @click="go('integral-acquisition')">积分获取说明</van-button>-->
                </div>
            </div>
        </div>

        <div class="list-box">
            <div class="my-list-cell">
                <div class="historical-records">
                    历史记录
                </div>
                <div class="resume-cell" v-for="i in list">
                    <div>
                        <van-icon name="peer-pay"/>
                        <span>{{ i.type }}</span>
                    </div>
                    <div class="eidt-font">
                       <span>
                           {{ i.createdDate }}
                       </span>
                        <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">{{ i.num }}
                        </van-button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getIntegral, checkIntegral, signIntegral, getUserInfo} from "../../api/user";
import { Toast } from 'vant';
    export default {
        data() {
            return {
              list:[],
              isSign:false,
              userInfo:{},
              params: {
                page: 0,
                size: 40,
              },
            }
        },
        mounted() {
          this.loadData();
          this.checkIntegral()
          this.loadUserInfo()
        },
        methods: {
          go(url) {
            this.$router.push(url);
          },
          async loadData() {
            let r = await getIntegral();
            this.list = r.data.content
          },
          async checkIntegral() {
            let r = await checkIntegral();
            console.info("checkIntegral",r.data)
            this.isSign = r.data!='未签到'?true:false;
          },
          async loadUserInfo() {
            let r = await getUserInfo();
            this.userInfo = r.data
          },
          async signIntegral() {
            if(this.isSign){
              Toast.fail("今天已经签过到了");
              return;
            }
            let r = await signIntegral();
            Toast.success("签到成功");
            this.isSign=true
            this.userInfo.integralNum=r.data
            this.loadData();
          },
        },
        computed: {}
    }

</script>

<style lang="less" scoped>
    .my-top {
        height: 280px;
        width: 100%;
        background: linear-gradient(to right, #0E91FC, #08ADFD);

        .info-box {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: space-around;

            .head-portrait {
                margin-top: 15%;
                width: 120px;
                height: 120px;
                box-sizing: border-box;
                padding: 5px;
                /*border: 5px solid #FFFFFF; 0C9BFC*/
                border-radius: 50%;
                /*text-align: center;*/
                background-image: linear-gradient(#FFFFFF, #08ADFD);

                .box {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #FFFFFF;
                    text-align: center;

                    .treasure-font {
                        padding-top: 10px;
                    }

                    .treasure-num {
                        margin-top: 10px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
                .treasure-btn-sign{
                  height: 20px;
                }
                .treasure-btn {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 20px);
                    background: linear-gradient(to right, #F7D48D, #FDA65D);
                    color: #A04306;
                    border: none;
                    font-size: small;
                }
            }
        }
    }

    .list-box {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: calc(100vh - 200px);
        margin-top: -30px;
        background: #FFFFFF;

        .historical-records {
            margin-top: 20px;
            margin-left: 15px;
            font-size: 20px;
            color: #3C3C3C;
            font-weight: 600;
        }

        .my-list-cell {
            margin-top: 20px;
            position: absolute;
            width: 100%;
          background: #fff;
            .resume-cell {
                max-width: 700px;
                padding: 15px;
                margin-top: 10px;
                margin-left: 5px;
                border-bottom: 1px solid #F7F7F7;
                color: #212121;
                font-size: 16px;

                span {
                    margin-left: 10px;
                }

                .eidt-font {
                    color: #C3C3C3;
                    font-size: xx-small;
                    margin-left: 15px;
                    margin-top: 10px;

                    .span-right {
                        float: right;
                        margin-right: 10px;

                        .span-btn {
                            height: 20px;
                            width: 50px;
                            background: linear-gradient(to right, #F7D48D, #FDA65D);
                            color: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }

</style>
